<template>
  <b-card :title="isCopy ? $t('copy') : $t('edit')">
    <b-row v-if="operation">
      <b-col sm="12">
        <strong>{{ $t('operation_type') }} - {{ operationType ? operationType.name : 'Пусто' }} = {{ currentAdditional ? currentAdditional.name : 'Пусто' }}</strong>
      </b-col>
      <b-col sm="2">
        <div class="form-group">
          <label for="">{{ $t('operation_type') }}</label>
          <select class="form-control form-control-sm" v-model="form.operationTypeId" @change="setType">
            <option value=""> -- </option>
            <option v-for="item in operation.types" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">{{ $t('sum') }}</label>
          <input type="text" class="form-control-sm form-control" v-model="form.sum">
        </div>
      </b-col>
      <b-col sm="2">
        <div class="form-group">
          <label for="">{{ $t('date') }}</label>
          <input type="date" class="form-control-sm form-control" v-model="form.date">
        </div>
        <div class="form-group">
          <label for="">{{ $t('sum_payed') }}</label>
          <input type="text" class="form-control-sm form-control" v-model="form.sumPaid">
        </div>
      </b-col>
      <b-col sm="2">
        <div class="form-group">
          <label for="">{{ $t('document_name') }}</label>
          <input type="text" class="form-control-sm form-control" v-model="form.documentName">
        </div>
        <div class="form-group">
          <label for="">{{ $t('payment_status') }}</label>
          <select class="form-control form-control-sm" v-model="form.paymentStatus">
            <option value="paid">{{ $t('paid') }}</option>
            <option value="unpaid">{{ $t('unpaid') }}</option>
          </select>
        </div>
      </b-col>
      <b-col sm="2">
        <div class="form-group">
          <label for="">{{ $t('document_status') }}</label>
          <select class="form-control form-control-sm" v-model="form.documentStatus">
            <option value="new">{{ $t('new') }}</option>
            <option value="waiting">{{ $t('waiting') }}</option>
            <option value="conducted">{{ $t('conducted') }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">{{ $t('operation_finance_type') }}</label>
          <select class="form-control form-control-sm" v-model="form.financeType">
            <option value="input">{{ $t('input') }}</option>
            <option value="output">{{ $t('output') }}</option>
          </select>
        </div>
      </b-col>
      <b-col sm="2">
        <div class="form-group">
          <label for="">{{ $t('company') }}</label>
          <select class="form-control form-control-sm" v-model="form.companyId" @change="setCompany">
            <option value=""> -- </option>
            <option v-for="item in operation.companies" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">{{ $t('cash_registers') }}</label>
          <select class="form-control form-control-sm" v-model="form.cashRegisterId">
            <option v-for="item in operation.cashRegisters" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
      </b-col>
      <b-col sm="2">
        <div class="form-group">
          <label for="">{{ $t('fio') }}</label>
          <select class="form-control form-control-sm" v-model="form.userId">
            <option v-for="item in operation.users" :key="item.id" :value="item.id">{{ item.last_name }} {{ item.first_name }} {{ item.surname }}</option>
          </select>
        </div>
      </b-col>
    </b-row>
    <hr>
    <component v-if="form.operationTypeId" :is="currentType" :currentDate="form.date" :operation="operation" :form="form"/>
  </b-card>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import CalcDropTable from './common/tables/calcdrop_table'
import CashTransferTable from './common/tables/cashtransfer_table'
import OtherSpendingTable from './common/tables/otherspending_table'
import PurchaseTable from './common/tables/parchese_table'
import SalaryTable from './common/tables/salary_table'
export default {
  components: {
    calcdrop_table: CalcDropTable,
    cashtransfer_table: CashTransferTable,
    otherspending_table: OtherSpendingTable,
    parchese_table: PurchaseTable,
    salary_table: SalaryTable
  },
  data: () => ({
    operation: null,
    currentAdditional: null,
    form: new Form({
      operationTypeId: '',
      date: '',
      documentName: '',
      documentStatus: 'new',
      companyId: '',
      userId: '',
      sum: '',
      sumPaid: '',
      paymentStatus: 'paid',
      financeType: 'input',
      cashRegisterId: 1
    }),
    currentType: ''
  }),
  mounted () {
    this.getData()
    this.form.date = this.currentDate()
    if (this.operation) {
      this.operation.additional.forEach(i => {
        i.selected = false
      })
    }
  },
  computed: {
    isCopy () {
      return this.$route.params.copy
    },
    operationType () {
      return this.operation.types.find(t => t.id === Number.parseInt(this.form.operationTypeId))
    }
  },
  methods: {
    async getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'finance').then(resp => {
        if (resp.data) {
          this.operation = resp.data
        }
      })
    },
    create () {},
    setType (e) {
      const typeId = Number.parseInt(e.target.value)
      const type = this.operation.types.find(t => t.id === typeId)
      this.currentAdditional = null
      if (typeof type !== 'undefined') {
        this.currentType = type.slug
        const additional = type.additional.map(a => a.id)
        this.operation.additional.forEach(i => {
          if (additional.indexOf(i.id) > -1) {
            i.selected = true
            this.currentAdditional = { name: i.name, id: i.id }
          } else {
            i.selected = false
          }
        })
      }
    },
    setCompany () {
      const companyId = Number.parseInt(this.form.companyId)
      if (companyId) {
        this.operation.users = this.operation.users.filter(i => {
          return i.company_ids && i.company_ids.company_id === companyId
        })
      } else {
        this.getData()
      }
    },
    currentDate () {
      const today = new Date()
      let dd = today.getDate()
      let mm = today.getMonth() + 1
      const yyyy = today.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      return yyyy + '-' + mm + '-' + dd
    }
  }
}
</script>
